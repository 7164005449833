document.addEventListener('DOMContentLoaded', function () {
    const menu = document.getElementById('menu');
    const menuWrapper = document.getElementById('menuWrapper');
    toggleClass(menu.parentElement, 'bg-white', false);
    toggleClass(menu.parentElement, 'shadow', false);

    function toggleClass(element, className, add) {
        element.classList[add ? 'add' : 'remove'](className);
    }

    function shrinkNav() {
        if (window.location.pathname.startsWith('/blog/') && window.location.pathname !== '/blog/') {
            return; // Beende das Script, wenn die URL eine Unterseite von "/blog" ist
        }
        const scrollTop = document.documentElement.scrollTop;
        const isScrolledDown = scrollTop >= 0.1;
        const isScrolledUp = scrollTop <= 30;
        if (isScrolledDown) {
            toggleClass(menu.parentElement, 'bg-white', true);
            toggleClass(menu.parentElement, 'shadow', true);
            toggleClass(menu.parentElement, 'sticky', true);
        } else if (isScrolledUp) {
            toggleClass(menu.parentElement, 'bg-white', false);
            toggleClass(menu.parentElement, 'sticky', false);
            toggleClass(menu.parentElement, 'shadow', false);
        }
    }

    // Überprüfen Sie die Scroll-Position beim Laden der Seite
    shrinkNav();

    window.addEventListener('scroll', shrinkNav);

    menuWrapper.addEventListener('click', function (event) {
        const trigger = event.target.closest('.dropdown-trigger');
        if (trigger) {
            const dropdownId = trigger.getAttribute('data-dropdown-id');
            toggleDropdown(dropdownId);
            event.stopPropagation();
        }
    });

    document.addEventListener('click', function (event) {
        if (!event.target.closest('.dropdown-container') && !event.target.closest('.dropdown-trigger')) {
            closeAllDropdowns();
        }
    });

    function toggleDropdown(activeDropdownId) {
        document.querySelectorAll('.dropdown-container').forEach(dropdown => {
            const trigger = dropdown.previousElementSibling;
            const isActive = trigger.getAttribute('data-dropdown-id') === activeDropdownId;
            const isCurrentlyVisible = !dropdown.classList.contains('hidden');

            if (isActive && isCurrentlyVisible) {
                dropdown.classList.add('hidden');
                trigger.querySelector('svg').classList.remove('rotate-180');
            } else if (isActive) {
                dropdown.classList.remove('hidden');
                trigger.querySelector('svg').classList.add('rotate-180');
            } else {
                dropdown.classList.add('hidden');
                trigger.querySelector('svg').classList.remove('rotate-180');
            }
        });
    }

    function closeAllDropdowns() {
        document.querySelectorAll('.dropdown-trigger').forEach(trigger => {
            trigger.querySelector('svg').classList.remove('rotate-180');
            trigger.nextElementSibling.classList.add('hidden');
        });
    }

    document.getElementById('mobileMenu').classList.remove('hidden');

});
