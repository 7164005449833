document.addEventListener("DOMContentLoaded", function () {
    // Get the height of the menu element
    var menuHeight = document.getElementById("menu").offsetHeight;

    // Function to smoothly scroll to the target element
    function smoothScroll(targetId) {
        var targetElement = document.getElementById(targetId);
        if (targetElement) {
            var targetPosition = targetElement.getBoundingClientRect().top;
            var offsetPosition = targetPosition - menuHeight;

            window.scrollBy({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    }

    // Add event listeners to all anchor links
    document.querySelectorAll('a[href^="#"]').forEach(function (anchor) {
        anchor.addEventListener("click", function (e) {
            e.preventDefault();

            var targetId = this.getAttribute("href").substring(1);
            smoothScroll(targetId);
        });
    });
});
