document.querySelectorAll('.copyLink').forEach(function(link) {
    var feedbackSpan = document.createElement('span');
    feedbackSpan.classList.add('feedback');
    feedbackSpan.classList.add('hidden');
    link.appendChild(feedbackSpan);

    link.addEventListener('click', function(event) {
        event.preventDefault(); // Verhindert das Standardverhalten des Links
        var linkToCopy = this.getAttribute('href'); // Der Link zum Kopieren
        var tempInput = document.createElement('input');
        tempInput.value = linkToCopy;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);

        // Zeige visuelles Feedback im Span-Element
        feedbackSpan.innerText = 'Link kopiert!';
        feedbackSpan.classList.toggle('hidden');
        setTimeout(function() {
            feedbackSpan.innerText = '';
            feedbackSpan.classList.toggle('hidden');
        }, 2000); // Nach 2 Sekunden das Feedback entfernen
    });
});