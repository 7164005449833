document.addEventListener('DOMContentLoaded', function () {
    const el = document.querySelector('.wobble-background');

    if (!el) {
        console.warn('Element with class "wobble-background" not found.');
        return;
    }

    // Alle Radial-Gradient-Elemente im SVG sammeln
    const gradientElements = Array.from(el.querySelectorAll('circle'));

    if (gradientElements.length === 0) {
        console.error('No radial gradients found in the SVG.');
        return;
    }

    // Extrahiere cx, cy und füllende Gradient-Elemente
    const gradientDetails = gradientElements.map((gradientElement) => {
        const cx = parseFloat(gradientElement.getAttribute('cx'));
        const cy = parseFloat(gradientElement.getAttribute('cy'));

        if (isNaN(cx) || isNaN(cy)) {
            console.warn('Invalid cx or cy value in radialGradient.');
            return {startX: 0, startY: 0}; // Standardwerte
        }

        return {startX: cx, startY: cy};
    });

    const offsets = Array.from({length: gradientDetails.length}, () => Math.random() * 360);
    const intensity = 25;
    const duration = 50;

    function generateOffset(angle) {
        return Math.sin(angle * Math.PI / 180) * intensity;
    }

    function updateGradients() {
        offsets.forEach((offset, index) => {
            offsets[index] = (offset + Math.random() * 5) % 360; // Offsets aktualisieren
        });

        gradientDetails.forEach((gradient, index) => {
            if (!gradient) {
                console.warn(`Gradient detail at index ${index} is undefined.`);
                return; // Wenn der Gradient nicht existiert, weiterfahren
            }

            const {startX, startY} = gradient;
            const x = generateOffset(offsets[index]);
            const y = Math.cos(offsets[index] * Math.PI / 180) * intensity;

            const gradientElement = gradientElements[index];
            if (gradientElement) {
                gradientElement.setAttribute('cx', `${startX + x}%`);
                gradientElement.setAttribute('cy', `${startY + y}%`);
            } else {
                console.error(`Gradient element at index ${index} not found.`);
            }
        });

        setTimeout(updateGradients, duration); // Timer für regelmäßige Updates
    }

    updateGradients(); // Initiales Update starten
});
